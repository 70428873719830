import { useState } from "react";
import Logo from "../../components/Logo/Logo";
import { PropertyOverlayIcon } from "../../icons/icons";
import styles from "./HomePage.module.css";

//@ts-ignore
import Lottie from 'react-lottie';
import animationData from './../../lotties/dot.json';

const HomePage: React.FC<{ setCurrentPage: (page: string) => void, setCurrentUnitInformations: (data: {}) => void }> = ({
    setCurrentPage,
    setCurrentUnitInformations
}) => {
    const [showUnitInfo, setShowUnitInfo] = useState(false);

    return (
        <div className={styles.container}>
            <Logo />
            
            


            <Bubble onClick={() => setCurrentUnitInformations({name: "B1"})} top="33%" left="19%">B1</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A1"})} top="33%" left="26%">A1</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A3"})} top="33%" left="32%">A3</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A5"})} top="33%" left="38%">A5</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A7"})} top="33%" left="44.5%">A7</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A9"})} top="33%" left="50.5%">A9</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A11"})} top="33%" left="57%">A11</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A13"})} top="33%" left="63%">A13</Bubble>

            <Bubble onClick={() => setCurrentUnitInformations({name: "A15"})} top="33%" left="69%">A15</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A16"})} top="33%" left="75%">A16</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A17"})} top="33%" left="81%">A17</Bubble>


            <Bubble onClick={() => setCurrentUnitInformations({name: "B2"})} top="44%" left="17.5%">B2</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A2"})} top="44%" left="24%">A2</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A4"})} top="44%" left="31%">A4</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A6"})} top="44%" left="37%">A6</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A8"})} top="44%" left="44%">A8</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A10"})} top="44%" left="50.5%">A10</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A12"})} top="44%" left="57.5%">A12</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A14"})} top="44%" left="64%">A14</Bubble>
            <Bubble onClick={() => setCurrentUnitInformations({name: "A'"})} top="44%" left="70.5%">A'</Bubble>



            {/* <div
                className={styles.overlayWrapper}
                onClick={() => setShowUnitInfo(true)}
            >
                <PropertyOverlayIcon color="#fff" />
            </div> */}

            
        </div>
    );
};


type BubbleProps = {
    children: any;
    top: string;
    left: string;
    onClick: () => void;
}

const Bubble = ({ children, top, left, onClick }: BubbleProps) => {

    return <>

        
    
        <div
            style={{
                position: "absolute",
                top: top,
                left: left,
                borderRadius: "50%",
                backgroundColor: "#08233A2E",
                zIndex: 100,
            }}
            onClick={onClick}
        >

            <div style={{opacity: 0.85}}>

            <Lottie 
                
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                    },
                }}
                height={56}
                width={56}
            />
            </div>

            <span style={{
                color: 'white',
                position: "absolute",
                left: (() => {
                    if (children === "A'") {
                        return "22px"
                    } else if (children.length === 2) {
                        return "18px"
                    } else if (children.length == 3) {
                        return "15px"
                    }
                })(),
                top: "16px",
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: "18px",
                fontWeight: 500,
            }}>{children}</span>

            {/* <div
                style={{
                    // position: "absolute",
                    // left: "8px",
                    // top: "8px",
                    marginLeft: "3px",
                    marginTop: "3px",
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#08233A70",
                    // opacity: 0.44,
                    zIndex: 100,
                }}
            onClick={onClick}

                // onClick={() => setShowUnitInfo(true)}
            >

                <div
                    style={{
                        position: "absolute",
                        left: "8px",
                        top: "8px",
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        backgroundColor: "#08233ACC",
                        zIndex: 100,
                    }}
            onClick={onClick}

                    // onClick={() => setShowUnitInfo(true)}
                >

                    

                </div>

            </div> */}

        </div>

    </>
}

export default HomePage;
