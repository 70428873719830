import { useState, useEffect } from "react";
import styles from "./LiveTourPage.module.css";

const HomePage: React.FC<{ setCurrentPage: (page: string) => void, setCurrentUnitInformations: () => void }> = ({
    setCurrentPage,
    setCurrentUnitInformations
}) => {
    const [showUnitInfo, setShowUnitInfo] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [checksum, setChecksum] = useState(0);

    useEffect(() => {
        setChecksum(checksum => checksum + 1);
    }, [currentIndex]);

    return (<>

        <iframe key={checksum} src={`https://masterplan.server.digital-lifestyle.io/360/frame.html?media-index=${currentIndex}`}  style={{overflow:"hidden", height:"100vh", width:"100vw"}} height="100%" width="100%" />


            <a className={`${styles.themeBtn} ${styles.topLeft}`} style={{background: "#08233acd"}} onClick={setCurrentUnitInformations}>للمزيد من التفاصيل</a>
            <a className={`${styles.themeBtn} ${styles.topRight}`} style={{background: "#08233acd"}} onClick={() => setCurrentPage("Aseeb")}>الرجوع</a>

            <div className={`${styles.btnsWrapper} ${styles.btnsInline} ${styles.btnsBottom}`}>
                <a onClick={() => setCurrentIndex(4)} className={`${styles.themeBtn}`} >المطبخ</a> {/* KITCHEN */}
                <a onClick={() => setCurrentIndex(5)}  className={`${styles.themeBtn}`}>غرفة المعيشة</a> {/* LIVING ROOM */}
                <a onClick={() => setCurrentIndex(0)} className={`${styles.themeBtn}`}  >مجلس</a> {/* GEUSTROOM DONE */} 
                <a onClick={() => setCurrentIndex(6)} className={`${styles.themeBtn}`} > المدخل </a> {/* ENTRANCE DONE */}
                <a onClick={() => setCurrentIndex(7)} className={`${styles.themeBtn}`}>المسبح</a> {/* POOL */}

                <a onClick={() => setCurrentIndex(22)} className={`${styles.themeBtn}`} >غرفة رئسية</a> {/* غرفة رئسية Master bedroom */}
                <a onClick={() => setCurrentIndex(26)} className={`${styles.themeBtn}`} >سطح</a> {/*  سطح Terasse */}
            </div>

            <div className={`${styles.btnsWrapper} ${styles.btnsInline} ${styles.btnsRight}`}>
                
                <a onClick={() => setCurrentIndex(25)} className={`${styles.themeBtn}`}>الطابق الثاني</a> {/* 3 */}
                <a onClick={() => setCurrentIndex(10)} className={`${styles.themeBtn}`}>الطابق الاول</a> {/* 2 */}
                <a onClick={() => setCurrentIndex(0)} className={`${styles.themeBtn}`}>الطابق الأرضي</a> {/* 1 */}
            
            </div>

            
        </>);
};

export default HomePage;
