import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./AseebPage.module.css";
import "./slide.css";
import DetailsButton from "../../components/DetailsButton/DetailsButton";
import BackButton from "../../components/BackButton/BackButton";
import AlbanTopBar from "../../components/AlbanTopBar/AlbanTopBar";
import AlbanUnitInformations from "../../components/AlbanUnitInformations/AlbanUnitInformations";
import { useState } from "react";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import Logo from "../../components/Logo/Logo";

const AlbanPage: React.FC<{ 
    setCurrentPage: (page: string) => void, 
    currentUnitInformations: false | {}, 
    setCurrentUnitInformations: () => void  }
> = ({
    setCurrentPage,
    currentUnitInformations,
    setCurrentUnitInformations
}) => {
    const [showUnitInfo, setShowUnitInfo] = useState(false);

    const toggleUnitInfo = () => {
        setShowUnitInfo(!showUnitInfo);
    };

    const handleIndexChange = (index: number) => {
        setCurrentBackground(images[index]);
    };

    const images = [
        "/assets/images/Aseeb/001.jpg",
        "/assets/images/Aseeb/002.jpg",
        "/assets/images/Aseeb/003.jpg",
        "/assets/images/Aseeb/004.jpg",
        "/assets/images/Aseeb/005.jpg",
        // "/assets/images/Aseeb/006.jpg",
        "/assets/images/Aseeb/007.jpg",
        "/assets/images/Aseeb/008.jpg",
        "/assets/images/Aseeb/009.jpg",
    ];

    const [currentBackground, setCurrentBackground] = useState(images[0]);
    return (
        <div className={styles.container}>

            <DetailsButton toggleShow={setCurrentUnitInformations} />
            <BackButton toggleShow={() => setCurrentPage("Aseeb")} />
            {/* <AlbanTopBar setCurrentPage={setCurrentPage} /> */}
            <div
                className={styles.carousel}
                style={{
                    left: currentUnitInformations !== false ? "25%" : "0%",
                    backgroundImage: `url(${currentBackground})`,
                }}
            >
                <ImageCarousel images={images} onIndexChange={handleIndexChange} />
            </div>
            {/* <AlbanUnitInformations toggleShow={toggleUnitInfo} show={showUnitInfo} /> */}
        </div>
    );
};

export default AlbanPage;
