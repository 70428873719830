import React, { useEffect, useState } from "react";
import HomePage from "./pages/HomePage/HomePage";
import AlbanPage from "./pages/AlbanPage/AlbanPage";
import AseebPage from "./pages/AseebPage/AseebPage";
import LiveTourPage from "./pages/LiveTourPage/LiveTourPage";
import UnitInformations from "./components/UnitInformations/UnitInformations";
import "./App.css";

function App() {
    const [currentPage, setCurrentPage] = useState("Aseeb");
    const [currentUnitInformations, setCurrentUnitInformations] = useState<false | {}>(false);
    const [oldCurrentUnitInformations, setOldCurrentUnitInformations] = useState<false | {}>(false);

    useEffect(() => {
        if (currentUnitInformations !== false) {
            setOldCurrentUnitInformations(currentUnitInformations);
        }
    }, [currentUnitInformations]);

    return (
        <div>

            {(() => {
                if (currentPage === "Aseeb") {
                    return <HomePage setCurrentPage={setCurrentPage} setCurrentUnitInformations={setCurrentUnitInformations} />
                } else if (currentPage === "Alban") {
                    return <AlbanPage setCurrentPage={setCurrentPage} />
                } else if (currentPage === "AseebPage") {
                    return <AseebPage setCurrentPage={setCurrentPage} currentUnitInformations={currentUnitInformations} setCurrentUnitInformations={() => setCurrentUnitInformations(oldCurrentUnitInformations)}  />
                } else if  (currentPage === "LiveTour") {
                    return <LiveTourPage setCurrentPage={setCurrentPage} setCurrentUnitInformations={() => setCurrentUnitInformations(oldCurrentUnitInformations)} />
                }
            })()}

            <UnitInformations
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                showUnitInfo={currentUnitInformations !== false}
                currentUnitInformations={currentUnitInformations}
                setCurrentUnitInformations={setCurrentUnitInformations}
                setShowUnitInfo={() => {}}
            />

        </div>
    );
}

export default App;