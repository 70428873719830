import styles from "./UnitInformationsForm.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Bounce } from "react-activity";
import "react-activity/dist/library.css";

interface UnitInformationsProps {
    currentUnitInformations: false | {};
}

const UnitInformationsForm: React.FC<UnitInformationsProps> = ({
    currentUnitInformations
}) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const sendData = (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(false);

        const formData = new FormData(e.target);
    
        // Access form data
        const email = formData.get('email');
        const phone = formData.get('phone');

        if (email && phone) {

            fetch('https://achraf.dev.etherial.fr/data', {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    phone,
                    project: currentUnitInformations
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
            .then(data => {
                
                setLoading(false);
                setSuccess(true);
                e.target.reset();

                setTimeout(() => {
                    setLoading(false);
                    setSuccess(false);
                }, 5000)

            }).catch(() => {
                setLoading(false);
            })

        } else {
            setLoading(false);
        }

    }
    

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {t(`unitInformations.registerInterest`)}
            </div>
            <div className={styles.description}>
                {t(`unitInformations.completeForm`)}
            </div>
            <form onSubmit={sendData}>
                <input
                    name="email"
                    type="text"
                    className={styles.input}
                    placeholder={t(`unitInformations.fullName`)}
                    required
                />
                <input
                    name="phone"
                    type="text"
                    className={styles.input}
                    placeholder={t(`unitInformations.phoneNumber`)}
                    required
                />

                <button className={
                    styles.button
                } disabled={loading}>

                    {(() => {

                        if (loading) {
                            return <Bounce color="#fff" size={16} speed={0.5} />
                        } else {
                            return t(`unitInformations.registerInterest`)
                        }

                    })()}

                    {/* {loading ? (
                            <Bounce color="#fff" size={16} speed={0.5} />
                    ) : t(`unitInformations.registerInterest`)} */}

                </button>

                <button className={styles.success} style={{opacity: success ? 1 : 0}}>
                    {t(`unitInformations.success`)}
                </button>
            </form>
        </div>
    );
};

export default UnitInformationsForm;
