import { Dispatch, SetStateAction, useState } from "react";
import { CloseIcon, GalleryIcon, VRIcon } from "../../icons/icons";
import UnitInformationsForm from "../Form/UnitInformationsForm/UnitInformationsForm";
import ProjectSwitchButton from "../ProjectSwitchButton/ProjectSwitchButton";
import styles from "./UnitInformations.module.css";
import { useTranslation } from "react-i18next";

interface UnitInformationsProps {
    currentPage: string;
    setCurrentPage: (page: string) => void;
    showUnitInfo: boolean;
    setShowUnitInfo: Dispatch<SetStateAction<boolean>>;
    currentUnitInformations: false | {};
    setCurrentUnitInformations: Dispatch<SetStateAction<false | {}>>;
}

const UnitInformations: React.FC<UnitInformationsProps> = ({
    setCurrentPage,
    showUnitInfo,
    setShowUnitInfo,
    currentUnitInformations,
    setCurrentUnitInformations,
    currentPage
}) => {
    const { t } = useTranslation();
    {/* @ts-ignore */}
    const name = currentUnitInformations.name

    const toggleUnitInfo = () => {
        setShowUnitInfo(!showUnitInfo);
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.card} ${showUnitInfo ? styles.show : ""}`}>
                <button className={styles.closeButton} onClick={() => setCurrentUnitInformations(false)}>
                    <CloseIcon color="#fff" />
                </button>
                <div className={styles.availability}>
                    {t(`unitInformations.available`)}
                </div>
                <div className={styles.title}>موذج {name}</div>
                <div className={styles.description}>
                    {t(`unitInformations.description`)}
                </div>

                <div className={styles.table}>
                    <div className={styles.tableRow}>
                        <span className={styles.itemName}>
                        مساحة البناء
                        </span>
                        <span className={styles.itemName}>{(() => {
                            if (name === "A17" || name === "A'") {
                                return "409";
                            } else if (name === "B1") {
                                return "633";
                            } else if (name === "B2") {
                                return "617.75";
                            } else {
                                return "312.50"
                            }
                        })()} متر مربع</span>
                    </div>
                    <div className={styles.tableRow}>
                        <span className={styles.itemName}>
                        مساحة الارض
                        </span>
                        <span className={styles.itemName}>
                        {(() => {
                            if (name === "A17" || name === "A'") {
                                return "375.85";
                            } else if (name === "B1") {
                                return "577.75";
                            } else if (name === "B2") {
                                return "577.75";
                            } else {
                                return "375.85"
                            }
                        })()} متر مربع
                        </span>
                    </div>
                </div>

                {currentPage !== "LiveTour" && <div className={styles.buttonsWrapper}>
                    <button className={styles.galleryButton} onClick={() => {
                        setCurrentPage("AseebPage") 
                        setCurrentUnitInformations(false)
                    }}>
                        <GalleryIcon color="#fff" />
                        {t(`button.viewGallery`)}
                    </button>
                    <button className={styles.exploreButton} onClick={() => {
                        setCurrentPage("LiveTour")
                        setCurrentUnitInformations(false)
                    }}>
                        <VRIcon color="#fff" />
                        {t(`button.explore360`)}
                    </button>
                </div>}
                {/* @ts-ignore */}
                <UnitInformationsForm currentUnitInformations={"Aseeb " + currentUnitInformations.name}/>
            </div>
            {(currentPage !== "LiveTour" && currentPage !== "AseebPage") && <ProjectSwitchButton
                setCurrentPage={setCurrentPage}
                show={showUnitInfo}
            />}

        </div>
    );
};

export default UnitInformations;
